import { LanguageSelector } from "@features/language-selector";
import { LogoutButton } from "@features/player/logout-button";
import { ThemeSwitch } from "@features/theme-switch";
import { useTranslate } from "@lobby/ocb-intl";
import { Logo, Modal } from "@shared/ui";
import { memo, useRef } from "react";
import {
  ProfileBalanceDetails,
  ProfilePlayerBalanceView,
  ProfileSectionControls,
  useProfileSections,
} from "../common";
import type { TProfileSectionItem } from "../common";
import { ProfileSectionMobile } from "./profile-section.mobile";

interface IProfileComponentMobileProps {
  currentSection: TProfileSectionItem | undefined;
  showLoadingOverlay: boolean;
  close: VoidFunction;
}

export const ProfileComponentMobile = memo(function ProfileComponentMobile({
  currentSection,
  showLoadingOverlay,
  close,
}: IProfileComponentMobileProps) {
  const { $t } = useTranslate();
  const transitionContainerRef = useRef<HTMLDivElement>(null);
  const currentSectionRef = useRef(currentSection);
  const sections = useProfileSections();

  const isHomeSection = !currentSection;
  currentSectionRef.current = isHomeSection ? currentSectionRef.current : currentSection;

  return (
    <Modal.Panel className="bg-mercury border-keppel dark:border-java dark:bg-ebony-clay size-full">
      <Modal.Title className="lg:justify-between p-3 mb-0">
        <Modal.CloseButton className="[&&]:relative" onClick={close} />
        <Logo className="ml-2.5 h-7" />
        <LogoutButton className="ml-auto" />
      </Modal.Title>

      <div
        className="flex min-h-0 h-full transition-transform duration-200 *:min-w-full *:w-full *:p-3 *:pt-0"
        ref={transitionContainerRef}
        style={{
          transform: `translateX(${isHomeSection ? "0%" : "-100%"})`,
        }}
      >
        <div
          className="overflow-y-auto overflow-x-hidden scrollbar-thin transition-opacity duration-200"
          style={{
            opacity: isHomeSection ? 1 : 0,
          }}
        >
          <ProfilePlayerBalanceView />
          <ProfileBalanceDetails className="mt-2" />
          <div className="mt-2">
            <ProfileSectionControls
              currentSectionId={currentSectionRef.current?.id}
              items={sections}
            />
          </div>
          <div className="py-5.5 flex-c-sb px-2.5">
            <div className="flex-center">
              <span className="text-blue-bayoux text-xs font-bold uppercase">
                {$t({ defaultMessage: "language" })}
              </span>
              <LanguageSelector />
            </div>
            <div className="flex-center gap-3">
              <span className="text-blue-bayoux text-xs font-bold uppercase">
                {$t({ defaultMessage: "color theme" })}
              </span>
              <ThemeSwitch />
            </div>
          </div>
        </div>

        <ProfileSectionMobile
          currentSection={currentSectionRef.current}
          isActive={!isHomeSection}
        />
      </div>

      <Modal.PendingOverlay isActive={showLoadingOverlay} />
    </Modal.Panel>
  );
});
