import { useTranslate } from "@lobby/ocb-intl";
import { Link } from "@tanstack/react-router";
import JsBarcode from "jsbarcode";
import { useEffect, useState } from "react";

import type { ApiClientResponse } from "@lobby/api-client";
import { MemberCard as MemberCardModel } from "@lobby/core/entities/member-card";
import { chunkString, pinSliced } from "@lobby/core/shared";
import { Button, Logo, SVGIcon, Skeleton } from "@shared/ui";
import { clsx } from "clsx";

const DEFAULT_BARCODE_VALUE = "0000000000000000";

export function BlankCard({ onClick }: { onClick: () => void }) {
  return (
    <div
      className="absolute z-[1] w-full flex-center rounded-8 bg-gradient-to-r from-[#e5e3e3] to-[theme(colors.keppel)] drop-shadow-[0_0.25rem_0.1250rem_rgba(0,0,0,0.35)] [aspect-ratio:1.6/1] dark:from-[theme(colors.java)] dark:to-[theme(colors.keppel)]"
      onClick={onClick}
    >
      <Logo className="w-2/3" />
    </div>
  );
}

export function BarcodeCard() {
  const [pinValue, setPinValue] = useState("000000");

  const { $t } = useTranslate();

  const { data: memberCard, isFetching } = MemberCardModel.useCurrent();
  const { mutate: generatePin, isPending: isPinGenerationPending } =
    MemberCardModel.usePinGenerator();

  const barcodeValue = memberCard?.memberCard || DEFAULT_BARCODE_VALUE;

  function pinUpdate(pin: number) {
    const formattedPin = pinSliced(pin);
    setPinValue(formattedPin);
  }

  function handlePinGenerationSuccess({ result }: ApiClientResponse<"MemberCard.generatePin">) {
    if (result) {
      pinUpdate(result);
    }
  }

  function handlePinGeneration(ev: React.MouseEvent<HTMLDivElement>) {
    ev.stopPropagation();

    generatePin(undefined, { onSuccess: handlePinGenerationSuccess });
  }

  useEffect(() => {
    if (memberCard?.pin) {
      pinUpdate(memberCard.pin);
    }
  }, [memberCard?.pin]);

  useEffect(() => {
    JsBarcode("#member-card-barcode", barcodeValue, {
      background: "transparent",
      font: "Ubuntu",
      fontOptions: "bold",
      fontSize: 12,
      textMargin: 8,
      margin: 0,
      height: 150,
      displayValue: false,
    });
  }, [barcodeValue]);

  return (
    <div className="absolute bottom-0 w-[96%] rounded-8 bg-gradient-to-r from-silver to-white text-[min(4vmin,16px)] drop-shadow-[0_0.25em_0.0625em_rgba(0,0,0,0.35)] [aspect-ratio:1.6/1]">
      <div className="absolute inset-0 flex-center flex-col gap-[0.5em] py-[1.5em]">
        <div className="flex h-[1em] min-h-0 items-center gap-[0.25em] font-bold text-black">
          <span className="text-[0.75em]">{$t({ defaultMessage: "PIN" })}:</span>
          {isFetching || isPinGenerationPending ? (
            <Skeleton className="h-full w-[2.5em] bg-nobel dark:bg-gray" />
          ) : (
            <span className="text-[0.75em]">{pinValue}</span>
          )}
          <div
            className="relative inline-block size-[1em] cursor-pointer"
            onClick={handlePinGeneration}
          >
            <SVGIcon
              className={`absolute size-full ${isPinGenerationPending ? "animate-spin" : "animate-none"}`}
              name="reload"
            />
          </div>
        </div>
        <svg className="mx-auto min-h-0 w-auto max-w-full grow" id="member-card-barcode" />
        {isFetching ? (
          <Skeleton className="h-[1em] w-1/2 dark:bg-gray" />
        ) : (
          <div className="h-[1em] min-h-0 font-bold text-black">
            <div className="text-[0.75em] leading-none">
              {chunkString(barcodeValue, 4)?.join("-")}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export function LogInWithBarcodeButton({ onClick }: { onClick: () => void }) {
  const { $t } = useTranslate();
  return (
    <Button type="primary" onClick={onClick}>
      {$t({ defaultMessage: "log in to the kiosk using barcode" })}
    </Button>
  );
}

export function BackToLobbyLink() {
  const { $t } = useTranslate();

  return (
    <Link
      to="/"
      className="mt-6 w-fit border-science-blue border-b-2 font-bold text-science-blue text-xs uppercase"
    >
      {$t({ defaultMessage: "back to the lobby" })}
    </Link>
  );
}

export function AddCardHint({ className }: { className?: string }) {
  const { $t } = useTranslate();

  return (
    <p className={clsx("mt-4 font-medium text-xs leading-6 tracking-wide", className)}>
      {$t(
        {
          defaultMessage:
            'Add the virtual Member Card to the home screen. In browser options press {icon} and tap "Add to home screen"!',
        },
        {
          icon: (
            <svg className="inline-block size-4" fill="none" viewBox="0 0 42 49">
              <path stroke="currentColor" strokeWidth="2" d="M12.96 15H1v33h40V15H28.66" />
              <path
                fill="currentColor"
                d="M21.7.3a1 1 0 0 0-1.4 0l-6.37 6.36a1 1 0 0 0 1.41 1.41L21 2.41l5.66 5.66a1 1 0 1 0 1.41-1.41L21.71.29ZM22 29V1h-2v28h2Z"
              />
            </svg>
          ),
        },
      )}
    </p>
  );
}
